const { hot } = require("react-hot-loader/root")

// prefer default export if available
const preferDefault = m => (m && m.default) || m


exports.components = {
  "component---cache-dev-404-page-js": hot(preferDefault(require("/site/.cache/dev-404-page.js"))),
  "component---src-pages-hubspot-blog-listing-js": hot(preferDefault(require("/site/src/pages/hubspot-blog-listing.js"))),
  "component---src-pages-hubspot-blogpost-js": hot(preferDefault(require("/site/src/pages/hubspot-blogpost.js"))),
  "component---src-templates-page-template-tsx": hot(preferDefault(require("/site/src/templates/page-template.tsx")))
}

